<template>
    <div class="org-info-page">
        <el-card class="basic-card" header="">
            <template slot="header">
                基本信息 
                <el-tooltip placement="top" class="margin-l-10">
                    <div class="line-height-1.5" slot="content">
                        版本信息<br>
                        AI翻译系统MT V1.7<br>
                        AI辅助翻译项目管理系统TMS V1.3<br>
                        AI独立工具 Agen V1.5<br>
                    </div>
                    <svg-icon name="ib-question"></svg-icon>
                </el-tooltip>
            </template>
            <div class="basic-info-form">
                <div class="form-item">
                    <div class="label">头像</div>
                    <div class="filed margin-l-20">
                        <el-avatar :src="org.logo" icon="el-icon-suitcase" :size="60" class="avatar"></el-avatar>
                    </div>
                </div>
                <div class="form-item">
                    <div class="label">公司名称</div>
                    <div class="filed margin-l-20">{{org.fullName}}</div>
                </div>
                <div class="form-item">
                    <div class="label">公司邮箱</div>
                    <div class="filed margin-l-20">{{org.email}}</div>
                </div>
                <div class="form-item">
                    <div class="label">联系人</div>
                    <div class="filed margin-l-20">{{org.contact}}</div>
                </div>
                <div class="form-item">
                    <div class="label">联系电话</div>
                    <div class="filed margin-l-20">{{org.contactPhone}}</div>
                </div>
                <div class="form-item">
                    <div class="label">公司简介</div>
                    <div class="filed margin-l-20">
                        <template v-if="org.profile">{{org.profile}}</template>
                        <span class="color-999" v-else>还未填写公司简介</span>
                    </div>
                </div>
                <el-button type="primary" size="small" round style="margin-top: 20px;" @click="editBtnEvent">修改信息</el-button>
            </div>
        </el-card>
        <el-dialog title="修改信息" :visible.sync="dialog.show" destroy-on-close @closed="handlerDialogClose" width="430px" top="20px">
            <div class="padding-x-20">
                <el-form size="medium" label-position="top">
                    <el-form-item label="头像" class="margin-b-20">
                        <!-- <el-upload class="avatar-upload" action="" accept=".jpg,.jpeg,.png"> -->
                            <el-avatar class="avatar" :src="dialog.form.logo" icon="el-icon-suitcase" :size="60"></el-avatar>
                            <!-- <div class="upload-icon">
                                <svg-icon name="ib-save"></svg-icon>
                            </div>
                        </el-upload> -->
                    </el-form-item>
                    <el-form-item label="企业名称" required class="margin-b-20">
                        <el-input v-model="dialog.form.name" placeholder="请输入企业名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="企业邮箱" class="margin-b-20">
                        <el-input v-model="dialog.form.email" placeholder="请输入企业邮箱" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" required class="margin-b-20">
                        <el-input v-model="dialog.form.contacts" placeholder="请输入联系人" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="联系手机" required class="margin-b-20">
                        <el-input v-model="dialog.form.phone" placeholder="请输入联系手机" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="公司简介" class="margin-b-20">
                        <el-input v-model="dialog.form.description" type="textarea" :rows="4" maxlength="250" show-word-limit clearable placeholder="请输入公司简介"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="text-center">
                <el-button size="mini" plain round @click="dialog.show = false">取消</el-button>
                <el-button type="primary" size="mini" round :loading="dialog.loading" @click="submitForm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { orgApi } from '@/utils/api';
import { mapState } from 'vuex';
import tools from '@/utils/tools';
export default {
    data() {
        return {
            dialog:{
                show: false,
                loading: false,
                form: {
                    logo: '',
                    name: '',
                    email: '',
                    contacts: '',
                    phone: '',
                    description: '',
                },
            },
            org: {},
        }
    },
    computed: {
        ...mapState({
            activeOrg: state => state.org.activeOrg,
        }),
    },
    created() {
        
    },
    methods: {
        initPage(){
            let url = `${orgApi.queryOrgInfo}?organizationId=${this.activeOrg.organizationId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.org = res.data;
                }
            });
        },
        editBtnEvent(){
            this.dialog.show = true;
            this.dialog.form = {
                logo: this.org.logo,
                name: this.org.fullName,
                email: this.org.email,
                contacts: this.org.contact,
                phone: this.org.contactPhone,
                description: this.org.profile,
            }
        },
        handlerDialogClose(){
            this.dialog = this.$options.data().dialog;
        },
        submitForm(){
            if(!this.dialog.form.name){
                this.$message.error('请填写企业名称');
                return;
            }
            if(!this.dialog.form.contacts){
                this.$message.error('请填写联系人');
                return;
            }
            if(this.dialog.form.phone){
                if(!tools.checkPhone(this.dialog.form.phone)){
                    this.$message.error('请填写正确的手机号');
                    return;
                }
            }else{
                this.$message.error('请填写联系手机');
                return;
            }
            if(this.dialog.form.email){
                if(!tools.checkEmail(this.dialog.form.email)){
                    this.$message.error('请填写正确的邮箱');
                    return;
                }
            }
            let url = orgApi.updateOrgInfo;
            let postData = {
                organizationId: this.activeOrg.organizationId,
                name: this.dialog.form.name,
                email: this.dialog.form.email,
                contact: this.dialog.form.contacts,
                phone: this.dialog.form.phone,
                profile: this.dialog.form.description,
            };
            this.dialog.loading = true;
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$message.success('修改成功');
                    this.org = res.data;
                    // this.initPage();
                }
            }).finally(() => {
                this.dialog.loading = true;
                this.dialog.show = false;
            })
        },
    },
    async mounted() {
        this.initPage();
    }
}
</script>
<style lang="scss" scoped>
.org-info-page{
    .basic-card {
        box-shadow: initial;
    }
    .avatar{
        // border-radius: 50%;
        // width: 60px;
        // height: 60px;
        font-size: 32px;
    }
    .basic-info-form{
        .form-item{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .label{
                font-size: 15px;
                line-height: 24px;
                padding-right: 20px;
                min-width: 60px;
            }
            .field{
                font-size: 15px;
                line-height: 24px;
            }
        }
    }
    .avatar-upload{
        width: 60px;
        height: 60px;
        ::v-deep .el-upload{
            position: relative;
        }
        .upload-icon {
            display: none;
            background-color: #000000B3;
            border-radius: 50%;
            position: absolute;
            top: 0;
            width: 100%;
            height: 60px;
            color: #fff;
            font-size: 28px;
            justify-content: center;
            align-items: center;
        }
        &:hover {
            .upload-icon{
                display: flex;
            }
        }
    }
}
</style>
